<nav class="navbar navbar-expand-lg navbar-light bg-primary white shadow">
  <div class="container-fluid">
    <div class="d-flex flex-row w-100">
      <div class="mob-menu" (click)="toggleSideNav()">
        <img src="assets/logo2.png" class="cursor-pointer logo" />
      </div>
      <div class="d-flex align-items-center justify-space-between w-100">
        <div class="header-text text-white text-bold w-100">
          {{activePage.name}}

        </div>
        <div class="d-flex justify-content-end collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <ul class="navbar-nav text-white">
            <div ngbDropdown class="d-inline-block dropdown-float">
              <button type="button" class="btn text-white" id="dropdownBasic1" ngbDropdownToggle>
                {{userProfile.uId || userProfile.username}}
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="logout()">Logout</button>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <!-- Navbar links -->
  </div>
</nav>