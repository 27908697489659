export const storeNavItems = [
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: "assets/icons/dashboard.svg",
        badge: {
            variant: 'info',
        }
    },

    {
        name: 'Manage Reports',
        url: '/store-views',
        icon: "assets/icons/star.svg",
        children: [
            {
                name: 'Stocks',
                url: '/store-views/view-stocks',
                icon: 'icon-cart'
            },
            {
                name: 'Stocks Value',
                url: '/store-views/performance/report/stock-value',
                icon: 'icon-cart',
            },
            {
                name: 'Service',
                url: '/store-views/view-service',
                icon: "assets/icons/star.svg",
              }, 
            {
                name: 'Invoice Report',
                url: '/store-views/report/invoice',
                icon: 'icon-cart'
            },
            {
                name: 'Sales Performance',
                url: '/store-views/performance/report/sales-performance',
                icon: 'icon-cart',
            },
            {
                name: 'Service Performance',
                url: '/store-views/performance/report/service-performance',
                icon: 'icon-cart',
            },
            {
                name: 'Customer Report',
                url: '/store-views/view-customers',
                icon: 'icon-cart'
            },
            {
                name: 'Customer Transactions',
                url: '/store-views/transactions/customer',
                icon: 'icon-cart'
            },
            {
                name: 'Employee Transactions',
                url: '/store-views/transactions/employee',
                icon: 'icon-cart'
            },
        ]
    },


    {
        name: 'Master Data',
        url: '/store-views',
        icon: "assets/icons/star.svg",
        children: [
            {
                name: 'Category',
                url: '/store-views/meta-data/category',
                icon: 'icon-cart'
            },
            {
                name: 'Sub-Category',
                url: '/store-views/meta-data/sub-category',
                icon: 'icon-cart'
            },
            {
                name: 'Brand',
                url: '/store-views/meta-data/brand',
                icon: 'icon-cart'
            },
            {
                name: 'Model',
                url: '/store-views/meta-data/model',
                icon: 'icon-cart'
            },
            {
                name: 'Color',
                url: '/store-views/meta-data/color',
                icon: 'icon-cart'
            },

            {
                name: 'Payment Method',
                url: '/store-views/meta-data/payment-mode',
                icon: 'icon-cart'
            },
            // {
            //     name: 'Expenses',
            //     url: '/store-views/meta-data/expense',
            //     icon: 'icon-cart'
            // },

            {
                name: 'Employee',
                url: '/store-views/employees',
                icon: 'icon-cart'
            },
            {
                name: 'Service Issues',
                url: '/store-views/meta-data/service-issue',
                icon: 'icon-cart'

            },
            {
                name: 'Operator',
                url: '/store-views/meta-data/network-operator',
                icon: 'icon-cart'

            },
            // {
            //     name: "Suppliers",
            //     url: "/store-views/suppliers",
            //     icon: 'icon-cart'
            // }, 
            // {
            //     name: "Closing Stock",
            //     url: "/store-views/meta-closing",
            //     icon: 'icon-cart'
            // }
        ]
    },
    {
        name: 'Manage Branch',
        icon: "assets/icons/star.svg",
        url: '/store-views/view-branch',
    },
    // {
    //     name: "Transactions",
    //     url: "/store-views/transactions",
    //     icon: "assets/icons/star.svg",
    // },
    {
        name: "Change Password",
        url: "/store-views/change-password",
        icon: "assets/icons/star.svg",
    }

];


// {
//     name: 'Sales Reports',
//     url: '/store-views/report',
//     icon: 'icon-cart'
// },