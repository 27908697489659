import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketService } from './service/websocket.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(private router: Router, private translate: TranslateService, private websocketService: WebSocketService) {
    translate.setDefaultLang('en');
  }
  ngAfterViewInit(): void {

    this.websocketService.getMessages().subscribe((message: any) => {
      if (message) {
        switch (message.type) {
          case 'file':
            this.downloadFile(message.data.url);
            break;
        }
      }
    })

  }
  private downloadFile(fileUrl: string): void {
    // Get the current host (protocol + hostname)
    const currentHost = `${window.location.protocol}//${window.location.hostname}`;
    // Construct the full URL, including the protocol
    const fullUrl = new URL(fileUrl, currentHost).toString();
    console.log('Full URL:', fullUrl); // Log fullUrl for debugging
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = fullUrl;
    link.target = '_blank'; // Open in a new tab
    link.download = ''; // Filename will be derived from the URL or server response
    // Append link to the body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the DOM
    document.body.removeChild(link);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });


  }



  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
