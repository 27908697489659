import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private totalRequests = 0;
  private maxRequests = 10; // Adjust based on expected concurrent requests
  private progress = new Subject<number>();
  private completeSubject = new BehaviorSubject<boolean>(true); // Start with all completed

  progress$ = this.progress.asObservable();
  complete$ = this.completeSubject.asObservable();

  startRequest() {
    this.completeSubject.next(false); // Indicate progress has started
    this.totalRequests++;
    this.updateProgress();
  }

  completeRequest() {
    if (this.totalRequests > 0) {
      this.totalRequests--;
      this.updateProgress();
      if (this.totalRequests === 0) {
        this.completeSubject.next(true); // Notify all requests are completed
      }
    }
  }

  private updateProgress() {
    // Calculate progress as a percentage
    const percentage = ((this.maxRequests - this.totalRequests) / this.maxRequests) * 100;
    this.progress.next(percentage);
  }
}
