import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket: Socket;
  private messageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private connectionStatusSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private reconnectInterval: number = 5000; // Interval in milliseconds to retry connection

  constructor() {
    this.connect().subscribe();
  }

  private connect(): Observable<void> {
    const token = this.getToken();

    if (!token) {
      console.error('No token found. Unable to establish WebSocket connection.');
      return new Observable<void>(); // Return an empty observable if no token is available
    }

    return new Observable<void>(observer => {
      try {
        // Initialize socket connection using Socket.io client
        this.socket = io(environment.socketUrl, {
          query: { token },
          reconnection: true, // Enable automatic reconnection
          reconnectionAttempts: Infinity, // Unlimited reconnection attempts
          reconnectionDelay: this.reconnectInterval, // Set delay between reconnection attempts
          reconnectionDelayMax: 10000, // Max delay for reconnection attempts
          transports: ['websocket'] // Force WebSocket transport for the connection
        });

        this.socket.on('connect', () => {
          console.log('Socket.io connection established');
          this.connectionStatusSubject.next(this.socket.connected ? 1 : 0);
          observer.next(); // Notify observer of successful connection
          observer.complete(); // Complete the observable
        });

        this.socket.on('disconnect', () => {
          console.log('Socket.io connection lost');
          this.connectionStatusSubject.next(0); // Set status to disconnected
        });

        this.socket.on('connect_error', (error) => {
          console.error('Socket.io connection error:', error);
          this.connectionStatusSubject.next(0); // Set status to error
          observer.error(error); // Notify observer of error
        });

        this.socket.on('message', (data: string) => {
          this.messageSubject.next(data);
        });

      } catch (error) {
        console.error('Socket.io connection failed:', error);
        this.connectionStatusSubject.next(0);
        observer.error(error); // Notify observer of error
      }
    });
  }

  sendMessage(message: any): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit('message', JSON.stringify(message));
    } else {
      console.error('Socket.io is not connected.');
    }
  }

  private getToken(): string {
    return localStorage.getItem('token')?.replace("JWT ", "") || '';
  }

  getMessages(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  getConnectionStatus(): Observable<number> {
    return this.connectionStatusSubject.asObservable();
  }
}
