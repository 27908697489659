import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  isSideNavOpened = new BehaviorSubject<boolean>(false);
  navList = new BehaviorSubject([]);

  userProfile = new BehaviorSubject({})
  activePage = new BehaviorSubject({
    icon: 'fa-home',
    name: 'Home',
  });
  constructor(private http: HttpClient) {}

  getNavList() {
    return this.navList.asObservable();
  }

  setNavList(navList: any) {
    this.navList.next(navList);
  }

  setActivePage(page: any) {
    this.activePage.next(page);
  }

  getUserProfile() {
   return this.userProfile.asObservable();
  }

  setUserProfile(user: any) {
    this.userProfile.next(user);
  }
}
