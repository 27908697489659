import { ApiResponse } from './../models/api-response.model';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/map';
import { reject } from 'q';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endPoint: any;

  public session: any

  constructor(public httpClient: HttpClient, public ngZone: NgZone, public router: Router) {


    this.endPoint = environment.apiUrl
    // this.endPoint = "http://localhost:8080/tallent_bill/"  //dev
  }
  authentication<T>(urlEnd: string, body: any): Promise<ApiResponse<T>> {
    return this.httpClient
      .post<ApiResponse<T>>(`${this.endPoint}${urlEnd}`, body)
      .timeout(300000) // Set the timeout
      .toPromise();
  }


  // Post Data with token
  post<T>(urlEnd, body, token?): Promise<ApiResponse<T>> {
    return this.httpClient.post<ApiResponse<T>>(this.endPoint + urlEnd, body).timeout(300000).toPromise()
  }

  // get Data with token
  get<T>(urlEnd, token?): Promise<ApiResponse<T>> {

    let queryString = "";
    if (typeof token !== 'string') {
      queryString += this.buildQueryString(token);
    }

    const url = `${this.endPoint}${urlEnd}${queryString ? `?${queryString}` : ''}`;
    return this.httpClient.get<ApiResponse<T>>(url).timeout(300000).toPromise()
  }

  buildQueryString(params: { [key: string]: any }): string {
    const queryStringParts = [];

    if (params?.keys) {
      const fieldString = params.keys.map((item) => {
        if (params.fpairs && params.fpairs[item]) {
          if (Array.isArray(params.fpairs[item])) {
            return `${item}:${params.fpairs[item].join("|")}`;
          }
          else {
            return `${item}:${params.fpairs[item]}`;
          }
        }
        else {
          return item;
        }
      }).join(",")
      queryStringParts.push(`fields=${fieldString}`);
    }

    if (params?.query) {
      queryStringParts.push(...Object.keys(params.query).map((item) => {
        return !["fields"].includes(item) ? `${item}=${JSON.stringify(params.query[item])}` : `${item}=${params.query[item]}`
      }))
    }
    return queryStringParts.join("&");
  }

  // get Data with token
  getWoToken(urlEnd) {
    return this.httpClient.get(this.endPoint + urlEnd).timeout(300000).toPromise()
  }
  // put Data with token
  put<T>(urlEnd, body, token?): Promise<ApiResponse<T>> {
    return this.httpClient.put<ApiResponse<T>>(this.endPoint + urlEnd, body).timeout(300000).toPromise()
  }

  // delete Data with token
  delete<T>(urlEnd, token?): Promise<ApiResponse<T>> {
    return this.httpClient.delete<ApiResponse<T>>(this.endPoint + urlEnd).timeout(300000).toPromise()
  }

  forgotPassword<T>(urlEnd, body): Promise<ApiResponse<T>> {
    return this.httpClient.post<ApiResponse<T>>(this.endPoint + urlEnd, body).timeout(300000).toPromise()
  }
}

