import { Component, Input, NgZone, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthServiceService } from '../../../service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  activePage: any;
  isSideNavOpened: boolean = false;
  userProfile: any;
  constructor(private authServiceService: AuthServiceService, private ngZone: NgZone, private router: Router) { }

  ngOnInit(): void {
    this.setHeaderName();
    // this.authServiceService.isSideNavOpened.subscribe(res => {
    //   this.isSideNavOpened = res;
    // })

    this.authServiceService.getUserProfile().subscribe(user => {
      if (user) {
        this.userProfile = user;
      }
    })
  }

  setHeaderName() {
    this.authServiceService.activePage.subscribe((res) => {
      this.activePage = res;
    });
  }

  @Output() toggleSideBar: EventEmitter<any> = new EventEmitter();

  toggleSideNav() {
    this.toggleSideBar.emit();
  }


  @Input()
  userName: string

  logout() {

    localStorage.clear()

    // this.sessionService.changeDate("");
    this.ngZone.run(() => {
      this.router.navigate([''])
    })
  }
}


