export const branchNavItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: "assets/icons/dashboard.svg",
  },
  // {
  //   name : "Tallent Pay",
  //   url : '/branch-views/tallent-pay',
  //   icon : 'icon-star',
  // },
  {
    name: 'Manage Stocks',
    icon: "assets/icons/star.svg",
    url: '/branch-views/view-stock',
  },
  {
    name: 'Manage Service',
    url: '/branch-views/view-service',
    icon: "assets/icons/star.svg",
  }, 
  // {
  //   name: 'Manage Purchase',
  //   url: '/branch-views',
  //   icon: "assets/icons/star.svg",
  //   children: [
  //     {
  //       name: 'Add Purchase',
  //       url: '/branch-views/add-purchase',
  //       icon: 'icon-cart'
  //     },
  //     {
  //       name: 'View Purchases',
  //       url: '/branch-views/view-purchase',
  //       icon: 'icon-cart'
  //     },
  //   ]
  // },
  //  {
  //   name: 'Manage Accounts',
  //   url: '/branch-views',
  //   icon: "assets/icons/star.svg",
  //   children: [
  //     {
  //       name: 'Accounts Closing',
  //       url: '/branch-views/accounts-closing',
  //       icon: 'icon-cart'
  //     },
  //     {
  //       name: 'Accounts History',
  //       url: '/branch-views/accounts-history',
  //       icon: 'icon-cart'
  //     },
  //   ]
  // },
   {
    name: 'Billing Service',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: 'Billing',
        url: '/branch-views/billing/sales',
        icon: 'icon-star',
      },
      {
        name: 'Purchase Return',
        url: '/branch-views/billing/purchase-return',
        icon: 'icon-star',
      },
      {
        name: 'Retailer',
        url: '/branch-views/billing/retailer',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Reports',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: 'Invoice Report',
        url: '/branch-views/report/invoice',
        icon: 'icon-cart',
      },
      {
        name: 'Stock Value Report',
        url: '/branch-views/report/performance/stock-value',
        icon: 'icon-cart',
      },
      {
        name: 'Sales Performance',
        url: '/branch-views/report/performance/sales-performance',
        icon: 'icon-cart',
      },
      {
        name: 'Service Performance',
        url: '/branch-views/report/performance/service-performance',
        icon: 'icon-cart',
      },
    ]
  },

  {
    name: 'View Customer',
    url: '/branch-views/view-customers',
    icon: "assets/icons/star.svg",
  },
  // {
  //   name: 'Manage Expenses',
  //   url: '/branch-views',
  //   icon: "assets/icons/star.svg",
  //   children: [
  //     {
  //       name: "Add Expenses",
  //       url: "/branch-views/expenses",

  //     },
  //     {
  //       name: 'View Expenses',
  //       url: "/branch-views/view-expenses",

  //     }
  //   ]
  // },
  {
    name: "Change Password",
    url: "/branch-views/change-password",
    icon: "assets/icons/star.svg",
  },



];
