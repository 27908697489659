export const superAdminNavItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: "assets/icons/dashboard.svg",

  },
  {
    name: 'Store',
    url: '/store',
    icon: "assets/icons/store.svg",
    children: [
      {
        name: 'Add Store',
        url: '/store/add-store',
        icon: 'icon-cart'
      },
      {
        name: 'View Store',
        url: '/store/view-store',
        icon: 'icon-cart'
      },
    ]
  },

];
