<div class="app-body d-flex w-100 h-100 align-items-center justify-content-center animated fadeIn">
  <notifier-container></notifier-container>

  <div class="card-group">
    <div class="card text-white bg-primary ">
      <div class="card-body text-center">
        <div>
          <!-- <h2>Sign up</h2> -->
          <h3>Welcome to Tallent Bill </h3>

          <img src="assets/logo2.png" alt="">

          <h5 class="italics-15">Smart. Simple. Safe.</h5>
          <!-- <button type="button" class="btn btn-primary active mt-3" routerLink='/register'>Register Now!</button> -->
        </div>
      </div>
    </div>


    <div class="card p-4 text-white" style="background-color: #282896">
      <div class="card-body">
        <!-- <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="jumbotron"> -->
        <form [formGroup]="loginParams" (ngSubmit)="login()" *ngIf="!forgot">
          <h1>Login</h1>
          <p class="text-muted">Sign In to your account</p>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-envelope"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Email" formControlName="emailId"
              autocomplete="username" required>
          </div>

          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="icon-lock"></i></span>
            </div>
            <input type="password" class="form-control" placeholder="Password" formControlName="password"
              autocomplete="current-password" required>
          </div>

          <div class="d-flex justify-content-between">
            <div>
              <button type="button" (click)="forgot = true" class="btn btn-link text-white px-0">Forgot
                Password</button>

            </div>
            <div>
              <button class="btn btn-primary " style="text-align:center;border-radius: 5px;" style="color: white">
                Login
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6 text-right text-white">


            </div>
          </div>

        </form>
        <app-forgot-password (forgot)="cancel($event)" *ngIf="forgot"></app-forgot-password>
      </div>
    </div>
  </div>

</div>


