<ng-container *ngIf="progress$ | async as progress">
  <ngb-progressbar *ngIf="progress < 100" [value]="progress" [animated]="true" class="floating-progress-bar">
  </ngb-progressbar>
</ng-container>

<div class="sidebar" [ngClass]="isSideNavOpened ? 'open' : ''">
  <div class="d-flex flex-row align-items-center">
    <div>
      <img src="assets/logo2.png" class="cursor-pointer logo" (click)="toggleSideBar()" />
    </div>
    <div class="logo-details pl-2 ">
      <h5 *ngIf="isSideNavOpened" class="logo_name">Tallent Bill</h5>
    </div>
  </div>

  <div class="nav-list pl-1" *ngIf="navItems">
    <li *ngFor="let navItem of navItems">
      <ng-container *ngIf="!navItem.children?.length">
        <a class="p-1" [routerLink]="navItem.url" routerLinkActive="active-nav-item"
          (click)="setActivePage(navItem);toggle(navItem)">
          <img [src]="navItem.icon" alt="{{ navItem.name }}" class="nav-icon" />
          <span class="links_name">{{ navItem.name }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="navItem.children?.length">
        <a class="p-1 cursor-pointer" (click)="toggle(navItem)">
          <img [src]="navItem.icon" alt="{{ navItem.name }}" class="nav-icon" />
          <span class="links_name">{{ navItem.name }}</span>
        </a>
      </ng-container>

      <!-- Handle children -->
      <ul *ngIf="navItem.children?.length" [@toggleMenu]="isOpen(navItem) ? 'open' : 'closed'" class="nav-sublist">
        <li *ngFor="let child of navItem.children">
          <a [routerLink]="child.url" routerLinkActive="active-nav-item" class="p-1" (click)="setActivePage(child)">
            <span class="links_name">{{ child.name }}</span>
          </a>
        </li>
      </ul>

    </li>
  </div>
</div>

<section class="home-section">
  <div class="header">
    <app-header (toggleSideBar)="toggleSideBar()"></app-header>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</section>