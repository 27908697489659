import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { SlideDown } from '../../../animation';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../../service/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ProgressBarService } from '../../../service/progress-bar.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [SlideDown,
    trigger('toggleMenu', [
      state('open', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      transition('open <=> closed', [
        animate('300ms ease-in-out')
      ]),
    ])
  ]
})
export class SideNavComponent implements OnInit {

  navItems: any[];
  @Output() logoClickEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('sidebar') sidebar: ElementRef;
  isSidebarCollapsed = false;
  progress$: any = this.progress.progress$;

  constructor(private ngZone: NgZone, private router: Router, private authServiceService: AuthServiceService, private progress: ProgressBarService) { }

  onNavItemClicked(index: number, childIndex?: any) {
    if (this.isSidebarCollapsed) {
      return;  // Exit if sidebar is collapsed
    }

    let nav = this.navItems[index];
    console.log(nav);
    this.navItems.map(item => {
      item.isActive = false;
      item.isOpen = false;
      if (item.children && item.children.length) {
        item.children = item.children.map((c: any) => {
          c.isActive = false;
          return c;
        });
      }
      return item;
    });

    this.navItems[index].isActive = true;

    if (nav.children) {
      this.navItems.map(item => {
        item.isOpen = false;
        return item;
      });
      this.navItems[index].isOpen = !this.navItems[index].isOpen;
    }

    if (childIndex > -1) {
      this.navItems[index].children[childIndex].isActive = true;
      this.goto(this.navItems[index].children[childIndex].url);
    } else {
      this.goto(this.navItems[index].url);
    }
  }

  goto(route: any) {
    this.ngZone.run(() => {
      this.router.navigate([route]);
    });
  }

  navEvent(event: any) {
    this.logoClickEvent.emit(event);
  }

  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);



  toggleSidebarWidth() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.sidebar.nativeElement.style.width = this.isSidebarCollapsed ? '30px' : '180px';

    if (this.isSidebarCollapsed) {
      this.closeAllItems();
    }
  }

  closeAllItems() {
    this.navItems.forEach(item => {
      item.isActive = false;
      item.isOpen = false;
      if (item.children && item.children.length) {
        item.children.forEach((child: any) => {
          child.isActive = false;
        });
      }
    });
  }


  isSideNavOpened = false;

  ngOnInit(): void {
    this.getNavItems();
    this.getNavStatus();
  }

  getNavStatus() {

    const sideBarState = localStorage.getItem('sideBarState');

    if (sideBarState) {
      this.isSideNavOpened = sideBarState === 'open';
      console.log(this.openStates);
    }
    else {
      this.authServiceService.isSideNavOpened.subscribe((res) => {
        this.isSideNavOpened = res;
        if (!this.isSideNavOpened) {
          this.openStates = {};
        }
      });
    }


  }

  toggleSideBar() {
    this.isSideNavOpened = !this.isSideNavOpened;
    this.authServiceService.isSideNavOpened.next(this.isSideNavOpened);
    localStorage.setItem('sideBarState', this.isSideNavOpened ? 'open' : 'close');
  }

  getNavItems() {
    this.authServiceService.navList.subscribe((res: any) => {
      if (res) {
        this.navItems = res;
      }
    });
  }

  setActivePage(page: any) {
    this.authServiceService.activePage.next(page);
    if (this.isMobile() || this.isTablet()) {
      this.isSideNavOpened = false;
    }
    this.authServiceService.isSideNavOpened.next(this.isSideNavOpened);
  }

  openStates = JSON.parse(localStorage.getItem('sideBarOpenStates')) || {};  // Object to track which items are open/closed

  toggle(navItem) {
    if (this.isSideNavOpened) {
      this.openStates[navItem.name] = !this.openStates[navItem.name];
      localStorage.setItem('sideBarOpenStates', JSON.stringify(this.openStates));
    }

  }

  isOpen(navItem) {
    return this.openStates[navItem.name];
  }


  isMobile(): boolean {
    return window.matchMedia('(max-width: 767px)').matches; // Example for mobile
  }

  isTablet(): boolean {
    return window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches; // Example for tablet
  }
}

