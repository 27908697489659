import { Component, OnDestroy, NgZone, OnInit, AfterViewInit } from '@angular/core';
import { superAdminNavItems } from '../../_SAnav';
import { branchNavItems } from '../../_BranchNav';
import { storeNavItems } from '../../_StoreNav';
import { Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { DefaultView } from './views/default-layout.view';
import { DefaultViewModel } from './views/default-layout.viewmodel';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthServiceService } from '../../service/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)'
      })),
      state('out', style({
        transform: 'translateX(-100%)'
      })),
      transition('in => out', [
        animate('300ms ease-in-out')
      ]),
      transition('out => in', [
        animate('300ms ease-in-out')
      ])
    ])
  ]

})
export class DefaultLayoutComponent implements OnDestroy, OnInit, AfterViewInit, DefaultView {

  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  session: any;
  public now;
  public mvDefaultViewModel;
  constructor(public ngZone: NgZone, public router: Router, public apiService: ApiService, private authService: AuthServiceService) {
    this.session = JSON.parse(localStorage.getItem("user_datas"))
  }

  isSideNavVisible = true;

  toggleSideNav() {
    this.isSideNavVisible = !this.isSideNavVisible;
  }


  ngAfterViewInit(): void {
    this.mvDefaultViewModel = new DefaultViewModel(this.apiService, this)
    this.mvDefaultViewModel.getProfile(this.session.token)
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  ngOnInit(): void {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.session)
    if (this.session == null || this.session == '') {
      this.ngZone.run(() => {
        this.router.navigate([''])
      })
    }
    else {
      if (this.session.user.role == "superadmin") {
        this.authService.setNavList(superAdminNavItems);
      }
      else if (this.session.user.role == "store") {
        this.authService.setNavList(storeNavItems);
      }
      else if (this.session.user.role == "branch") {
        this.authService.setNavList(branchNavItems);
      }
    }
  }

  OnFailed(err) {

  }
  OnNetworkError(err) {

  }

  userName: any = "Username"
  OnProfileResponse(response) {
    if (this.session.user.role == "store") {
      this.userName = response['userId']
    }
    if (this.session.user.role == "branch") {
      this.userName = response['userId']
    }
    if (this.session.user.role == "superadmin") {
      this.userName = "SUPER ADMIN"
    }

    response.username = this.userName;

    this.authService.setUserProfile(response)

  }


  logoClickEvent(event: any) {
    console.log("Clikced")
  }

  get sideNavState() {
    return this.isSideNavVisible ? 'in' : 'out';
  }


}
