import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ProgressBarService } from './service/progress-bar.service';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {

  constructor(private progress: ProgressBarService, private router: Router, private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.progress.startRequest();
    this.spinner.show('base');
    this.progress.complete$.subscribe((complete) => {
      if (complete)
        this.spinner.hide('base');
    })


    // Check if the request URL ends with '.json'
    if (request.url.endsWith('.json')) {
      this.progress.completeRequest();
      return next.handle(request);
    }

    const user = localStorage.getItem('user_datas') ? JSON.parse(localStorage.getItem('user_datas')!) : null;

    // Clone the request to add the new header, if user and user.token are available
    const clonedRequest = user && user.token
      ? request.clone({
        headers: request.headers.set('Authorization', `${user.token}`)
      })
      : request;

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.clear();
          // Navigate to the login page on 401 Unauthorized
          this.router.navigate(['/']);
        }
        return throwError(() => error); // Re-throw the error after handling
      }),
      finalize(() => {
        this.progress.completeRequest();

      })
    );
  }
}
